import {useEffect} from 'react';
import {Link, Route} from 'react-router-dom';

import {InvoiceService} from '../service/InvoiceService';

import {TraCuu} from './tra-cuu';

const invoiceService = new InvoiceService();

export const App = () => {

	useEffect(() => {
		invoiceService.getIntroduction().then(data => {
			if (data?.description?.trim()) {
				// setDisplayAds(data.description);
			}
		}).catch(() => {
		});
	}, []);

	return (
		<div className="App">
			<div className="layout-topbar">
				<div className="flex align-items-center justify-content-between">
					<Link to="/" className="layout-topbar-logo">
						<img src={'/assets/homecasta/logo.png'} alt="logo"/>
					</Link>
					<ul className="iam-topbar-actions">
						<li className="iam-topbar-action">
							<a className="p-d-flex p-flex-row p-ai-center" href="https://zalo.me/2754224749728949264" rel="noreferrer" target="_blank" title="Hỗ trợ trực tiếp">
								<img src="/assets/images/zalo-60x60.png" alt="" width="30" style={{marginRight: '0.5rem'}}/>
								<span>Hỗ trợ trực tiếp</span>
							</a>
						</li>
					</ul>
				</div>
			</div>

			<div>
				<Route path="/" exact component={TraCuu}/>
			</div>

			<div className="layout-footer text-center text-white p-5">
				<b>CÔNG TY TNHH TƯ VẤN VÀ DỊCH VỤ HOME CASTA</b>
				<p><i className="pi pi-map-marker mr-2"/>Địa chỉ: 37/66 Trung Hành, Phường Đằng Lâm, Quận Hải An, Thành phố Hải Phòng, Việt Nam.</p>
				<p><i className="pi pi-phone mr-2"/><b>Điện thoại:</b> 0816.111.286</p>
			</div>
		</div>
	);
}
